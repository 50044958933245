// import { timeoutGet, timeoutPost, timeoutPut, dataURItoBlob } from "hooks/http";
import { shouldLog } from "utils/shouldLog";
import { workerPost, workerGet, workerPut } from "workers/interfaceRest";

export const s3ToTheme = async (userID, digest) => {
  let presignedResponse = await s3PresignedURL({
    collection: "theme",
    row: digest + ".json",
    table: userID,
    method: "getObject"
  });
  if (presignedResponse) {
    let getResult = await workerGet(presignedResponse.url, undefined, {});
    return getResult;
  } else return undefined;
};

export const s3ToSuite = async (userID, digest) => {
  let presignedResponse = await s3PresignedURL({
    collection: "suite",
    row: digest + ".json",
    table: userID,
    method: "getObject"
  });
  if (presignedResponse) {
    let getResult = await workerGet(presignedResponse.url, undefined, {});
    return getResult;
  } else return undefined;
};

export const s3ToDescriptor = async (collection, descriptor, digest) => {
  let presignedResponse;
  try {
    presignedResponse = await s3PresignedURL({
      collection: collection,
      row: digest + ".json",
      table: descriptor,
      method: "getObject"
    });
  } catch (e) {
    console.log(e);
  }
  if (presignedResponse) {
    let getResult;
    try {
      getResult = await workerGet(presignedResponse.url, undefined, {});
      shouldLog() && console.log("Topic_presignedResponse", getResult);
      return getResult;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  } else return undefined;
};

export const s3ToImage = async (scope, digest) => {
  let presignedResponse = await s3PresignedURL({
    collection: "image",
    row: digest + ".json",
    table: scope,
    method: "getObject",
  });
  if (presignedResponse) {
    let getResult;
    try {
      getResult = await workerGet(presignedResponse.url, undefined, {});
      return getResult;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  } else return undefined;
};

export const s3PresignedURL = async (options) => {
  return workerPost(
    process.env.REACT_APP_S3_API_URL,
    process.env.REACT_APP_S3_API_KEY,
    options
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const uploadToS3_not = async (item) => {
  let mpersona = item.mpersona;
  let digest = item.digest;
  let j = {};
  // find file type
  if (item.colors) {
    // this is a theme
    j = {
      collection: "theme",
      table: "public",
      row: item.id + ".json",
      method: "postObject"
    };
  } else if (item.b64) {
    // this is an image
    j = {
      collection: "image",
      table: item.mpersona || item.scope,
      row: digest + ".json",
      method: "postObject"
    };
  } else if (digest && mpersona) {
    // this is a descriptor
    j = {
      collection: "descriptor",
      table: mpersona,
      row: digest + ".json",
      method: "postObject"
    };
  }
  let presignedResponse = await s3PresignedURL(j);
  let postItem = { ...item };
  delete postItem._id;
  delete postItem._deleted;
  delete postItem._rev;
  let postResult = await workerPost(
    presignedResponse.url,
    process.env.REACT_APP_S3_API_KEY,
    postItem,
    presignedResponse.fields
  )
    .then((r) => r)
    .catch((err) => {
      console.log(err);
    });
  return postResult;
};

export const uploadToS3 = async (item) => {
  let mpersona = item.mpersona;
  // delete item._id;
  // delete item._deleted;
  // delete item._rev;
  let digest = item.digest;
  let j = {};
  // find file type
  if (item.colors) {
    // this is a theme
    j = {
      collection: "theme",
      row: item.id + ".json",
      table: "public",
      method: "putObject"
    };
  } else if (item.b64) {
    // this is an image
    j = {
      collection: "image",
      row: digest + ".json",
      table: item.mpersona || item.scope,
      method: "putObject"
    };
  } else if (digest && mpersona) {
    // this is a descriptor
    j = {
      collection: "descriptor",
      row: digest + ".json",
      table: mpersona,
      method: "putObject"
    };
  }
  let presignedResponse = await s3PresignedURL(j);
  let putItem = { ...item };
  delete putItem._id;
  delete putItem._deleted;
  delete putItem._rev;
  delete putItem._rev;
  let putResult = await workerPut(
    presignedResponse.url,
    process.env.REACT_APP_S3_API_KEY,
    "undefined",
    {
      body: JSON.stringify(putItem),
      type: item.b64 ? "application/json" : "application/json"
    }
  )
    .then((r) => r)
    .catch((err) => {
      console.log(err);
    });
  return putResult;
};

export const uploadFileToS3 = async (file, collection, table, row) => {
  let j = {
    collection: collection,
    table: table,
    row: row,
    method: "putObject"
  };
  let presignedResponse = await s3PresignedURL(j);
  let putResult = await workerPut(
    presignedResponse.url,
    process.env.REACT_APP_S3_API_KEY,
    "undefined",
    {
      body: file,
      type: file?.type || "multipart/form-data"
    }
  )
    .then((r) => {
      return r;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return putResult;
};

export const s3ToFile = async (collection, table, row) => {
  let presignedResponse = await s3PresignedURL({
    collection: collection,
    row: row,
    table: table,
    method: "getObject",
  });
  if (presignedResponse) {
    let getResult = await workerGet(presignedResponse.url, undefined, {});
    return getResult;
  } else return undefined;
};
