import { useReducer } from "react";
import isEqual from "react-fast-compare";
import { shouldLog } from "utils/shouldLog";

const reducer = (state, action) => {
  shouldLog() && console.log("SET user", state, action);
  switch (action.type) {
    case "SET_TOKEN":
      shouldLog() && console.log("SET_TOKEN", action);
      if (
        isEqual(state.accessToken, action.values.accessToken) &&
        isEqual(state.parsedToken, action.values.parsedToken) &&
        isEqual(state.idToken, action.values.idToken)
      )
        return state;
      else
        return {
          ...state,
          accessToken: action.values.accessToken,
          parsedToken: action.values.parsedToken,
          idToken: action.values.idToken
        };
    default: {
      return state;
    }
  }
};

const useUserState = (state) => {
  const [userState, userDispatch] = useReducer(reducer, state);
  return { userState, userDispatch };
};

export default useUserState;
